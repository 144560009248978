import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { styledPayStatus } from '../styled/styledPayStatus';

const PayCancel = ({ className }) => (
  <Layout className={className}>
    <SEO title="Thank you" />
    <div className="message">
      <span>Your payment was cancelled</span>
      <span>We have not deducted any funds from your account.</span>
      <span>This window will close shortly. You may close it manually</span>
    </div>
  </Layout>
);

export default styledPayStatus(PayCancel);
